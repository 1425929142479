@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(
    92.23deg,
    #2F58CD 100%,
    #3795BD 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(47, 88, 205, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #2F58CD 100%,
    #3795BD 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #2F58CD 100%,
    #3795BD 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

.section{
  min-height: 100vh;
  height: fit-content !important;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#2F58CD] to-[#3795BD] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex min-h-screen;
  }
}

/* canvas- styles */
.canvas-loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
    1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
